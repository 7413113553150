module.exports = [{
        title: 'format order',
        group: 'penjualan',
        template: 'Baik kak, Untuk pemesanan bisa melalui WA ini\ndengan melengkapi data berikut :\nNama : \nAlamat Email : \nPhone Number : \nAlamat Pengiriman : \nKode produk : \nUkuran : '
    },
    {
        title: 'format order lengkap',
        group: 'penjualan',
        template: 'Terimakasih telah berbelanja di Tuneeca <% if(cust.newOrder) { %>\nTotal pesanan untuk no <%= cust.newOrder.formalId%> yaitu sebesar Rp <%= cust.newOrder.amount%> dengan rincian masing - masing produk : \n<% cust.newOrder.lines.forEach(e => { %>\n<%= e.product.localSku%> - <%= e.product.name%> - <%= e.qty%><%= e.qty > 1 ? "pcs" : "pc"%> - Rp <%= e.finalPrice%><% }) %><% } %>\n<% if(cust.lastOrder) { %>\nuntuk format order untuk pesanan terakhirnya :\nNama : <%=cust.lastOrder.shippingAddress.name%>\nAlamat Email : <%=cust.lastOrder.shippingAddress.primaryEmail%>\nPhone Number : <%=cust.lastOrder.shippingAddress.primaryMobile%>\nAlamat Pengiriman : <%=cust.mergedAddress%>\nKode produk : <%=cust.lastOrder.lines[0].product.localSku%>\nUkuran : <%= cust.lastOrder.lines[0].properties ? cust.lastOrder.lines[0].properties[0].name : ""%>\n\napakah format order menggunakan format yang sama? jika formatnya berbeda Kakak dapat mengisi format order : <% } else { %>\nsilahkan Kakak untuk mengisi format order : <% }  %>\nNama : \nAlamat Email : \nPhone Number :\nAlamat Pengiriman : \nKode produk :\nUkuran : \n\nsetelah mengisi format order, Kakak melakukan pembayaran dengan transfer ke salah satu no rekening berikut ya kak\n\n- BCA: 777-157-0007 a/n PT Bina Fajar Estetika,\n- BSI: 720-090-0997 a/n PT. Bina Fajar Estetika\n- Multipayment MANDIRI dengan kode perusahaan 40020 .\n\nMohon lampirkan bukti transfer jika sudah melakukan pembayaran dan mohon cantumkan nomor nota pada keterangan pembayaran. Terima kasih'
    },
    {
        title: 'format alamat',
        group: 'penjualan',
        template: 'Baik kak, Untuk pengiriman pesanan bisa melalui WA ini\ndengan melengkapi data berikut :\nNama Penerima :\nKota/Kabupaten :\nKecamatan :\nKelurahan :\nProvinsi :\nKode Pos :\nAlamat : '
    },
    {
        title: 'format alamat lengkap',
        group: 'penjualan',
        template: '<% if(cust.lastOrder) { %>untuk alamat pengiriman pada pesanan sebelumnya dengan tujuan kirim :\nNama Penerima : <%=cust.lastOrder.shippingAddress.name%>\nKota/Kabupaten : <%=cust.lastOrder.shippingAddress.city%>\nKecamatan : <%=cust.lastOrder.shippingAddress.district%>\nKelurahan : \nProvinsi : <%=cust.lastOrder.shippingAddress.province%>\nKode Pos : <%=cust.lastOrder.shippingAddress.postalCode%>\nAlamat : <%=cust.lastOrder.shippingAddress.street%>\n\napakah tujuan pengiriman menggunakan alamat yang sama? jika alamat penerima berbeda Kakak dapat mengisi format : <% } else { %>\nsilahkan Kakak untuk mengisi format pengiriman : <% }  %>\nNama Penerima :\nKota/Kabupaten :\nKecamatan :\nKelurahan :\nProvinsi :\nKode Pos :\nAlamat : '
    },
    {
        title: 'penerimaan pesanan',
        group: 'penjualan',
        template: 'Alhamdulillah, kami senang sekali jika kakak suka dengan produk kami. Kami tunggu foto cantiknya mengenakan produk kami ya kak. Jangan lupa upload fotonya di Instagram dengan hashtag #TuneecaOTD, dan dapatkan hadiah menarik jika terpilih menjadi The Best OTD yang akan diumumkan setiap minggunya. Terimakasih telah berbelanja di Tuneeca ya kak. '
    },
    {
        title: 'Teguran partner (harga tidak sesuai)',
        group: 'partnership',
        template: 'Assalamu\'alaikum, Wr, Wb \nSalam Kak, melalui pesan ini kami ingin menyampaikan beberapa hal mengenai penjualan produk Tuneeca yang kakak jual melalui ________ (Shopee/WA/FB/IG/Marketplace). Terimakasih karena Kakak sudah bekerjasama dengan kami untuk memperluas pasar Tuneeca Group. Namun selain memperluas jangkauan pasar, kami juga harus menjaga stabilitas harga jual di pasar Tuneeca Group. Tuneeca sendiri telah memberikan ketentuan yang saat ini masih berlaku yaitu prihal besaran diskon yang dapat diberikan Reseller/Agen ke customer. Maksimal besaran diskon tersebut adalah 10% untuk semua produk Tuneeca Group kecuali Merchandise. Besaran diskon khusus hanya berlaku pada reseller dengan minimum pembelanjaan yang sudah ditentukan.  \n\nMengacu pada hal di atas, kami harap kakak dapat menyesuaikan besaran diskon produk yang kakak pasarkan pada (Marketplace/IG/WEB/FB) agar sesuai ketentuan diskon maksimal produk baru/produk harga normal yaitu 10% saja. Mohon kerja samanya agar dapat melakukan pemasaran produk sesuai dengan ketentuan yang telah ditetapkan, karena dengan menjaga stabilitas harga jual, kakak juga sudah membantu kami memperluas pasar Tuneeca Group. Kami tunggu update selanjutnya prihal besaran diskon yang sudah dikoreksi menjadi 10%. \n\nKami akan menghubungi kakak kembali jika dalam 2 hari ke depan, besaran diskon masih belum berubah. Mohon kerja samnaya ya kak, terimakasih. '
    },
    {
        title: 'Partner bayar komitmen awal EDP',
        group: 'produk',
        template: 'Baik Bu/Mbak/Bunda, transfer sudah kami terima, untuk proses selanjutnya akan di follow up dengan PIC EDP Tuneeca. Semoga proses pembuatan EDPnya lancar dan produknya best seller. Terimakasih.'
    },
    {
        title: 'FU jika Partner sudah bergabung (classic)',
        group: 'partnership',
        template: '"Terimakasih telah berbelanja produk kami, dan dikarenakan pembelanjaan sudah mencapai lebih dari Rp. 5.500.000,- setelah dipotong diskon Partnership, maka kakak secara otomatis sudah tergabung dalam keanggotaan Partnership Tuneeca Group. Kakak akan mendapatkan diskon khusus keagenan di kategori Agen Contemporer dengan skema diskon sebagai berikut : 1. Pembelian koleksi Tuneeca New dan Poeva (Non Doskon) akan mendapatkan diskon 20%, 2. Pembelian koleksi Almari Tuneeca (Non Diskon) akan mendapatkan diskon 20% 3. Pembelian koleksi Rhupa (non diskon) akan mendapatkan diskon 20% 4. Pembelian koleksi Tuneeca Signature akan mendapatkan diskon 0% 5. Dan untuk pembelian produk diskon akan mendapatkan tambahan diskon sampai dengan 7% (misal produk diskon 50% kakak akan mendapatkan diskon 57%) *Diskon akan terpotong otomatis setiap melakukan pembelanjaan di Web Akun kakak sudah aktif di web kami, berikut akunnya : IAkun Partnership kakak sudah aktif di web kami dan sudah bisa digunakan untuk berbelanja, berikut akunnya : ID : ____________ Password : ___________ *Silahkan melakukan penggantian Password untuk keamanan akun. Akun sudah aktif dan sudah bisa digunakan untuk berbelanja Setiap Partner berhak mendapatkan informasi seputar promo dan launching produk baru yang akan dikirimkan ke email dan WA kakak. Kakak dapat melakukan promosi produk melalui materi promosi yang kami kirimkan melalui WA. Untuk konfirmasi pesanan, keluhan, dan pertanyaan seputar Tuneeca group serta Partnership dapat kakak akses melalui WA ini ya kak. Kakak akan dilayani oleh CS Tuneeca yaitu mbak Gita Islami. Salam kenal ya kak, semoga kedepannya kita bisa bersinergi dengan baik. Selamat bergabung..."'
    },
    {
        title: 'FU jika Partner sudah bergabung (start up)',
        group: 'layanan konsumen',
        template: 'Terimakasih telah berbelanja produk kami, dan dikarenakan pembelanjaan sudah mencapai lebih dari Rp. 5.500.000,- setelah dipotong diskon Partnership, maka kakak secara otomatis sudah tergabung dalam keanggotaan Partnership Tuneeca Group. Kakak akan mendapatkan diskon khusus keagenan di kategori Agen Contemporer dengan skema diskon sebagai berikut :\n\n1. Pembelian koleksi Tuneeca New dan Poeva (Non Doskon) akan mendapatkan diskon 20%,\n2. Pembelian koleksi Almari Tuneeca (Non Diskon) akan mendapatkan diskon 20%\n3. Pembelian koleksi Rhupa (non diskon) akan mendapatkan diskon 20%\n4. Pembelian koleksi Tuneeca Signature akan mendapatkan diskon 10%\n5. Dan untuk pembelian produk diskon akan mendapatkan tambahan diskon sampai dengan 7% (misal produk diskon 50% kakak akan mendapatkan  diskon 57%)\n*Diskon akan terpotong otomatis setiap melakukan pembelanjaan di Web\n\nAkun kakak sudah aktif di web kami, berikut akunnya :\nIAkun Partnership kakak sudah aktif di web kami dan sudah bisa digunakan untuk berbelanja, berikut akunnya :\nID : ____________\nPassword : ___________\n*Silahkan melakukan penggantian Password untuk keamanan akun. Akun sudah aktif dan sudah bisa digunakan untuk berbelanja \n\n\nSetiap Partner berhak mendapatkan informasi seputar promo dan launching produk baru yang akan dikirimkan ke email dan WA kakak. Kakak dapat melakukan promosi produk melalui materi promosi yang kami kirimkan melalui WA. Untuk konfirmasi pesanan, keluhan, dan pertanyaan seputar Tuneeca group serta Partnership dapat kakak akses melalui WA ini ya kak. Kakak akan dilayani oleh CS Tuneeca yaitu mbak Gita Islami. Salam kenal ya kak, semoga kedepannya kita bisa bersinergi dengan baik. Selamat bergabung...'
    },
    {
        title: 'persyaratan member dan keagenan (classic)',
        group: 'partnership',
        template: '"Untuk syarat bergabung menjadi Partnership Classic, cukup dengan melakukan pembelanjaan pertama sebesar Rp. 3.500.000,- dan mengisi data diri berikut :\nNama :\nPhone/WA :\nEmail :\nAlamat Lengkap :\nSerta mengirim Foto KTP yang masih berlaku.'
    },
    {
        title: 'persyaratan member dan keagenan (start up)',
        group: 'partnership',
        template: '\nUntuk syarat bergabung menjadi Partnership Classic, cukup dengan melakukan deposit sebesar Rp. 3.500.000,- dan mengisi data diri berikut :\nNama :\nPhone/WA :\nEmail :\nAlamat Lengkap :'
    },
    {
        title: 'group whatsapp (classic)',
        group: 'partnership',
        template: 'ada kak, dan Group whatsapp saat ini tersedia hanya untuk Reseller Tuneeca :) '
    },
    {
        title: 'group whatsapp (start up)',
        group: 'partnership',
        template: 'Group whatsapp tersedia apabila sudah terdaftar menjadi agen Tuneeca'
    },
    {
        title: 'opening classic',
        group: 'partnership',
        template: 'Assalamu\'laikum wr.wb, Selamat siang kak perkenalkan saya <%= operator.userFullName %> dari tuneeca, sebelumnya mau konfirmasi dari data yang kami dapat bahwa disini kakak nya tertarik untuk bergabung menjadi agen/reseller tuneeca. Bisa diinfokan sebelumnya apa kakak sudah mengenal Tuneeca atau pernah membeli produk Tuneeca Group? Bila Kakak berkenan, kami ingin menghubungi kakak melalui telepon untuk menjelaskan lebih lanjut perihal program ini, sekiranya kapan ya Kakak bisa kami hubungi? Kami tunggu responnya ya kak, terima kasih.'
    },
    {
        title: 'opening start up',
        group: 'partnership',
        template: 'Assalamu\'laikum wr.wb,\n\nSelamat siang kak perkenalkan saya <%= operator.userFullName %> dari tuneeca, sebelumnya mau konfirmasi dari data yang kami dapat bahwa disini kakak nya tertarik untuk bergabung menjadi agen/reseller tuneeca.  Bisa diinfokan sebelumnya apa kakak sudah mengenal Tuneeca atau pernah membeli produk Tuneeca Group?\n\nBila Kakak berkenan, kami ingin menghubungi kakak melalui telepon untuk menjelaskan lebih lanjut perihal program ini, sekiranya kapan ya Kakak bisa kami hubungi? Kami tunggu responnya ya \'kak\', terima kasih.\n'
    },
    {
        title: 'kendala modal classic',
        group: 'partnership',
        template: 'jika kakak keberatan untuk melakukan deposit terlebih dahulu, kakak bisa mencari customernya dahulu dengan menawarkan produk yang tersedia di website, setelah permintaan customer dengan nominal mencukupi nominal salah satu jenis partnership, baru kakak melakukan pendaftaran'
    },
    {
        title: 'kendala modal start up',
        group: 'partnership',
        template: 'baik kak, kaka bisa mempromosikan terlebih dahulu produknya. nanti jika jumlah pemesanan sudah mencapai minimal 3,5juta silahkan hubungi kami kembali untuk melakukan pendatftaran partnership. akan kami bantu untuk pembuatan akun nya dan otomatis mendapat diskon kak :) '
    },
    {
        title: 'stok sedikit dan ingin di keep classic',
        group: 'partnership',
        template: 'kakak bisa konfirmasikan untuk kode busana yang ingin di keep kepada kami agar stock aman, masa penyimpanan barang yang di keep maksimal 1 minggu dari tanggal konfirmasi. jika melebihi waktu tersebut, barang akan kami realease kembali'
    },
    {
        title: 'stok sedikit dan ingin di keep start up',
        group: 'partnership',
        template: 'kakak bisa konfirmasikan untuk kode busana yang ingin di keep kepada kami agar stock aman, masa penyimpanan barang yang di keep maksimal 1 minggu dari tanggal konfirmasi. jika melebihi waktu tersebut, barang akan kami realease kembali\n'
    },
    {
        title: 'min diskon untuk cust classic',
        group: 'partnership',
        template: 'Kakak dapat memberikan diskon sebesar maksimum 10%'
    },
    {
        title: 'min diskon untuk cust start up',
        group: 'partnership',
        template: 'kakak bisa memberikan diskon kepada customer maksimal 10%'
    },
    {
        title: 'benefit keagenan classic',
        group: 'partnership',
        template: '1.Potongan diskon sebesar 20% disetiap pembelian produk-produk dengan harga normal \n2. Memiliki kuota retur sebesar 50% dari total belanja bulan sebelumnya. Kuota retur akan update setiap awal bulan dengan perhitungan kuota sebesar 50% dari total belanja di bulan sebelumnya. \n3. Mendapatkan tambahan potongan diskon untuk produk-produk dengan kategori promo. misalnya produk tersebut sudah diskon 50% maka kakak akan mendapatkan tambahan diskon kembali sebesar 7-10% \n4. Sebagai member resmi Tuneeca, kakak memiliki kesempatan besar untuk meningkatkan level partnershipnya menjadi partnership Contemporer juka pembelanjaan sudah mencapai lebih dari Rp. 5.500.000,-. Diskon akan naik menjadi 27% untuk produk dengan harga normal. \n5. Ada kesempatan untuk menjadi Mitra/Distributor jika selama 6 bulan ada 4 kali pembelanjaan mencapai lebih dari Rp. 40.000.000,-. Besaran diskon untuk distributor adalah 35% untuk produk dengan harga normal. Dan dapat membuka jaringan reseller untuk mempercepat pertumbuhan bisnis Partner. \n6. Free biaya Charge Packing produk \n7. Free design untuk banner Tuneeca ataupun brosur/flyer untuk promosi di tempat kakak. \n8. Dapat mengikuti Launching produk terbaru sebelum produk Launching di website, \n9. Mendapatkan soft katalog produk terbaru sebelum launching di website dan masih banyak lagi benefit-benefit yang akan kakak dapatkan, \n10. Mendapatkan edukasi mengenai penggunaan web dan sistem pemasaran'
    },
    {
        title: 'benefit keagenan start up',
        group: 'partnership',
        template: '1. Potongan diskon sebesar 20% disetiap pembelian produk-produk dengan harga normal \n2. Mendapatkan tambahan potongan diskon untuk produk-produk dengan kategori promo. misalnya produk tersebut sudah diskon 50% maka kakak akan mendapatkan tambahan diskon kembali sebesar 7-10% \n4. Bebas target belanja bulanan, bisa belanja kapan saja jika sudah mengukti Partnership Start-up,  \n5. Free biaya Charge Packing produk \n6. Mendapatkan informasi soft katalog produk terbaru,\n7. Mendapatkan edukasi mengenai penggunaan web dan sistem pemasaran \n'
    },
    {
        title: 'Booking produk hilang',
        group: 'kendala web',
        template: 'Dear Partner, mohon maaf atas ketidak nyamannya. Kami akan menyampaikan permasalahan ini kepada Tim terkait. Mohon diinformasikan kode produk dan size produk yang hilang dalam bookingan Partner. Kami membutuhkan waktu kurang lebih selama _____ menit untuk melakukan pengecekan pada akun Partner. Mohon menunggu informasi selanjutnya dari kami. Terimakasih'
    },
    {
        title: 'Cuti partnership (Belum diambil)',
        group: 'kendala web',
        template: 'Dear Partner, pengajuan Partner untuk cuti atau tidak mencapai minimal pembelanjaan bulanan di bulan ____________ telah disetujui, dikarenakan dalam  periode MOU baru di tahun ini Partner belum mengambil kesempatan untuk melakukan Cuti Partnership. Kami harap setelah cuti Partnership ini, Partner dapat kembali lagi bekerja sama dengan semangat dan motivasi baru. Terimakasih. '
    },
    {
        title: 'Syarat Order EDP',
        group: 'partnership',
        template: 'Dear Partner, semua Partner diperbolehkan untuk melakukan order EDP dengan design yang Partner tentukan sendiri dengan minimal kuantitiy order sebanyak 50 pcs. Partner dipersilahkan untuk membayar biaya komitmen pembuatan EDp sebesar Rp. 3.500.000,-. Jika EDP Partner jadi diproduksi, maka Partner akan mendapatkan Cashback dari biaya komitmen awal sebesar Rp. 2.500.000,- dan 1 pcs sample produk dengan sizepack yang partner tentukan sendiri (sesuai sizepack produk Tuneeca). Berikut ini kami lampirkan ketentuan dari pemesanan EDP, setelah Partner setuju dengan ketentuannya, Partner dapat meklakukan transfer biaya komitmen pembuatan EDP. Kami tunggu kabar baik dari Partner. Terimakasih.'
    },
    {
        title: 'Follow up (4) setelah pembayaran',
        group: 'partnership',
        template: 'Dear Partner, terimakasih telah melakukan pelunasan untuk tagihan ____________________. Semoga bisnis Partner lancar dan selalu diberikan kemudahan dalam segala urusannya. Kami ucapkan terimakasih atas kerja samanya. Salam hangat kami untuk Partner dan keluarga. '
    },
    {
        title: 'Produk sale cacat',
        group: 'produk',
        template: 'Dear Partner, terimakasih telah membeli produk kami. Mohon maaf jika Partner atau customer Partner kurang puas dengan produk kami dikarenakan ada masalah dengan produk yang diterima. Untuk produk tersebut dapat diretur atau ditukar dengan produk lain atau produk yang sama yang masih tersedia dengan besaran diskon akan disesuaikan dengan diskon sebelumnya.. Namun jika Partner/customer Partner tetap menginginkan produk tersebut, dapat kami perbaiki dengan lama waktu perbaikan kurang lebih 10 hari kerja. Kami tunggu keputusan dari partner/Customer Partner. Terimakasih.'
    },
    {
        title: 'Produk non sale cacat',
        group: 'produk',
        template: 'Dear Partner, terimakasih telah membeli produk kami. Mohon maaf jika Partner atau customer Partner kurang puas dengan produk kami dikarenakan ada masalah dengan produk yang diterima. Untuk produk tersebut dapat diretur atau ditukar dengan produk lain atau produk yang sama yang masih tersedia. Namun jika Partner/customer Partner tetap menginginkan produk tersebut, dapat kami perbaiki dengan lama waktu perbaikan kurang lebih 10 hari kerja. Kami tunggu keputusan dari partner/Customer Partner. Terimakasih.'
    },
    {
        title: 'Lap. pelanggaran diskon tidak sesuai',
        group: 'kendala web',
        template: 'Dear Partner, terimakasih untuk laporannya. Mohon maaf jika hal ini diluar kontrol kami dan kami harus mengetahuinya dari partner. Selanjutnya akan kami tindak lanjuti. Kami sangat mengapresiasi jika pada laporan ini ada bukti-bukti seperti screenshoot percakapan atau postingan dari Seller yang menjual atau menawarkan produk dengan diskon diluar ketentuan. Kami mohon Partner dapat terus melaporkan kepada kami jika Partner mengetahui ada perkembangan lebih lanjut. Terimakasih.'
    },
    {
        title: 'rekomendasi produk berdasakan tema',
        group: 'produk',
        template: 'Halo Kak, terimakasih telah berkunjung ke Webstore kami. Kami memiliki banyak rekomendasi baju untuk __________________ (isikan occasion yang diminta customer). Berikut beberapa rekomendasi produk kami yang kemungkinan pas dengan keinginan kakak. '
    },
    {
        title: 'cara memasukan voucher',
        group: 'layanan konsumen',
        template: 'Halo kak, untuk promo tersebut kode vouchernya adalah _____________. kakak dapat memasukan kode voucher di kolom input voucher yang disediakan pada halaman checkout pesanan. Untuk lebih jelasnya kakak bisa melihat tutorialnya pada link berikut ya kak ---> https://bit.ly/TutorialInputVoucher \n\nMohon beri tahu kami jika kakak masih mengalami kesulitan atau ada error saat memasukan vouchernya. Terimakasih.'
    },
    {
        title: 'konfirmasi pembayaran',
        group: 'pembayaran',
        template: 'Halo kak, untuk trfnya sudah kami terima ya kak, pesanan akan kami kirimkan ke alamat kakak. Jika sudah terima produknya jangan lupa untuk foto dan upload fotonya di Instagram dengan Hash tag #tuneecaotd. Akan ada hadiah menarik jika foto kakak terpilih menjadi The Best Outfit Of The Day. Terimakasih telah berbelanja produk kami. :)\n\nJika terdapat kerusakan atau kehilangan barang, untuk barang yang dikirim tanpa menggunakan asuransi akan mendapatkan penggantian maksimal 10x dari biaya pengiriman. terima kasih atas konfirmasinya'
    },
    {
        title: 'stok produk (habis)',
        group: 'produk',
        template: 'Halo kak, wah sayang sekali kak untuk produk tersebut sudah habis semua ukurannya. Namun kakak tidak usah khawatir karena kami masih memilliki banyak varian produk lainnya. Jika kakak berkenan, kami coba kirimkan rekomendasi produknya kak melalui WA ini.'
    },
    {
        title: 'kuota retur classic',
        group: 'retur',
        template: 'kuota retur untuk partnership classic 50% dan partnership contemporer 30% dari total pemesanan baju perbulannya'
    },
    {
        title: 'kuota retur partnership',
        group: 'retur',
        template: 'partnerKuota retur untuk Reseller Tuneeca yaitu 50% dari total pemesanan baju perbulannya'
    },
    {
        title: 'Sizepack kecil (di bagian badan)',
        group: 'produk',
        template: 'Salam bunda/ibu/mbak, Untuk baju tersebut telah kami nlakukan pengukuran dan hasilnya masih sesuai standar sizepack kami. Hanya saja pada produk tersebut terdapat detail atau cutting di bagian _________ (badan/Lengan/pinggul/) yang menyebabkan sizepack terasa lebih kecil dari biasanya. Untuk permasalahan ini, kami sarankan agar dikura dengan size yang lebih besar atau dengan varian produk lainnya yang masih tersedia.'
    },
    {
        title: 'Fu komponen tidak tersedia',
        group: 'produk',
        template: 'Salam bunda/Ibu/Partner, terimakasih atas kesediaannya untuk menunggu informasi dari kami. Kami ingin informasikan jika komponen pengganti untuk baju yang ingin diperbaiki sudah tidak tersedia. Mohon maaf untuk baju tersebut tidak bisa kami perbaiki.  Terimakasih.'
    },
    {
        title: 'FU komponen tersedia',
        group: 'produk',
        template: 'Salam bunda/Ibu/Partner, terimakasih atas kesediaannya untuk menunggu informasi dari kami. Kami ingin informasikan jika komponen penggantiu untuk baju yang ingin diperbaiki masih tersedia. Untuk perbaikan baju tersebut Partner akan dikenakan biaya perbaikan sebesar Rp. __________ dengan estimasi penyelesaian perbaikan selama ________ hari. Jika Partner berkenan silahakan untuk mengirimkan baju yang akan diperbaiki ke alamat __________________________________ (cantumkan alamat Solokan Jeruk). Terimakasih.'
    },
    {
        title: 'Akun partner aktif (turun grade)',
        group: 'layanan konsumen',
        template: 'Salam Ibu, saat ini akun Ibu sudah normal kembali. Previllage Launching Partner yang selama 1 bulan ini dinon aktifkan, akan kami aktifkan kembali. Sehingga pada periode Launching selanjutnya akun Ibu akan aktif dan bisa mengikuti Launching Partner. Mohon dijaga kuota cancel Nota nya agar tidak melebihi 15% dengan cara :\n1. Aktif proses nota yang telah dibuat sebelum masa expire nota yaitu 3x24 jam,\n2. Konfirmasikan kepada CS kami untuk nota yang fix di ambil dan akan dilakukan pembayaran agar dimasukan ke dalam list nota proses dan aman dari Cancel by sistem, durasi keep nota yang fix di ambil adalah 1x24 jam.\n\n Demikian informasi ini kami sampaikan, Atas kerja samanya kami ucapkan terimakasih.'
    },
    {
        title: 'Kuota cancel booking',
        group: 'partnership',
        template: 'Salam Ibu, saat ini akun Ibu sudah normal kembali. Sudah release dari pembatasan waktu booking karena kuota booking yang lebih dari 15%. Akun Ibu kembali normal mulai per hari ini dengan waktu booking normal yaitu 1x24 jam. Mohon dijaga kuota cancel bookingnya agar tidak melebihi 15%. Atas kerja samanya kami ucapkan terimakasih.'
    },
    {
        title: 'komplain model loose/sejenis Abaya',
        group: 'produk',
        template: 'Salam Ibu/Bunda/Mbak, mohon maaf jika produk kurang berkenan, namun produk tersebut merupakan produk dengan desain yang loose atau mirip seperti desain abaya. Sehingga dikarenakan modelnya loose maka akan berdampak pada ukurannya yang lebih besar. '
    },
    {
        title: 'Sizepack tidak sesuai produk sale',
        group: 'produk',
        template: 'Salam Ibu/Bunda/Mbak, mohon maaf karena produk kami tidak sesuai. Produk tersebut merupakan produk yang sudah launching lebih dari 6 bulan yang lalu. Dan sejak produk tersebut di launching hingga saat ini, kami belum menerima komplain mengenai Sizepacknya. Namun jika produk yang diterima Ibu/Bunda/Mbak merasa tidak sesuai dengan ukuran biasanya, dapat diretur kembali. Kami akan melakukan pengecekan pada sizepack produk yang Ibu/Bunda/Mbak terima. Jika ternyata sizepack produk tersebut memeang tidak sesuai, kami akan kirimkan produk baru yang sesuai sizepacknya atau bunda bisa tukar dengan produk lainnya yang masih tersedia di website kami. Dan dikarenakan produk tersebut merupakan produk diskon, maka untuk ongkos kirim akan menjadi tanggungan customer. Mohon menunggu info lebih lanjut dari hasil pengecekan yang kami lakukan. '
    },
    {
        title: 'SIze Pack tidak sesuai produk non sale',
        group: 'produk',
        template: 'Salam Ibu/Bunda/Mbak, mohon maaf karena produk kami tidak sesuai. Untuk produk tersebut telah kami lakukan pengukuran dan sudah sesuai dengan sizepack kami. Namun jika produk yang diterima Ibu/Bunda/Mbak merasa tidak sesuai dengan ukuran biasanya, dapat diretur kembali. Kami akan melakukan pengecekan pada sizepack produk yang Ibu/Bunda/Mbak terima. Jika ternyata sizepack produk tersebut memeang tidak sesuai, kami akan kirimkan produk baru yang sesuai sizepacknya atau bunda bisa tukar dengan produk lainnya yang masih tersedia di website kami. Mohon menunggu info lebih lanjut dari hasil pengecekan yang kami lakukan. '
    },
    {
        title: 'Cuti Partnership alasan tertentu (cuti sudah diambil)',
        group: 'partnership',
        template: 'Salam Ibu/Bunda/Mbak, terimakasih atas kepercayaannya pada kami karena telah berkenan menceritakan permasalahan yang Ibu/Bunda/Mbak alami yang menjadi penyebab bulan ini Partner akan mengambil cuti partnership/tidak mencapai minimal pembelanjaan bulanan. '
    },
    {
        title: 'Penangguhan pembayaran partner',
        group: 'partnership',
        template: 'Salam Ibu/Bunda/Mbak, terimakasih atas kepercayaannya pada kami karena telah berkenan menceritakan permasalahan yang Ibu/Bunda/Mbak alami yang menjadi penyebab keterlambatan pembayaran. Kami akan sampaikan ke tim terkait prihal keterlambatan pembayaran tersebut. Mohon menunggu informasi selanjutnya dari kami. Terimakasih.'
    },
    {
        title: 'Perbaikan Baju dengan garansi habis',
        group: 'produk',
        template: 'Salam ibu/Mbak/Bunda mohon maaf untuk baju tersebut sudah berakhir masa garansinya, dikarenakan sudah lebih dari 1 tahun launching. Karena itu jika Ibu menginginkan baju tersebut masuk perbaikan maka akan ada biaya perbaikannya. Namun sebelumnya akan kami tanyakan ke pihak terkait mengenai ketersediaan kain/gasper/kancing/belt/ untuk mengganti baju yang rusak. Mohon menunggu informasi selanjutnya dari kami. Terimakasih.'
    },
    {
        title: 'Konfirmasi stok error',
        group: 'produk',
        template: 'Salam kak, mohon maaf berikut beberapa nota yang masuk proses hari ini, namun tidak bisa kami lanjutkan prosesnya dikarenakan stock error ya kak. Untuk nota berikut akan terhapus otomatis oleh system, dikarenakan stocknya minus. \n\nberikut data invoice yang Gagal kirim karena stock error :\n\n\nMohon maaf atas ketidak nyamanannya. Jika ingin melakukan pergantian stok, mohon agar segera dikonfirmasikan ya kak. Terimakasih'
    },
    {
        title: 'produk hilang karena expire',
        group: 'produk',
        template: 'Salam kak, saat ini fitur booking kakak sedang tidak aktif dikarenakan kuota cancel booking kakak telah habis atau melebihi 15%. Fitur booking akan aktif kembali setelah 30 hari dari konfirmasi dinonaktifkannya fitur booking yang dikirimkan melalui email. Saat Launching, fitur booking akan tetap aktif selama 1 jam yang memungkinkan kakak untuk tetap bisa membuat nota dropship.'
    },
    {
        title: 'Jika produk hilang karena expire',
        group: 'produk',
        template: 'Salam, terimakasih telah menunggu. Setelah kami melakukan pengecekan pada akun Partner, kami mendapati  jika produk tersebut terhapus secara otomatis dari bookingan Partner dikarenakan expire/kadaluarsa. kami lampirkan data produk yang expire dari bookingan Partner berikut ini. Jika produk masih tersedia, kami sarankan partner melakukan pemesanan kembali dan segera dijadikan Invoice agar produk dapat segera ditransaksikan dan dilakukan pengiriman. Namun jika produk sudah tidak tersedia, Parner dapat memilih produk lainnya yang masih tersedia di website kami. Demikian penjelasana dari kami, semoha Partner berkenan. Atas perhatian dan kerja samanya kami ucapkan terimakasih.'
    },
    {
        title: 'sisa deposit ketika stop start up partner',
        group: 'partnership',
        template: 'start uptidak hangus koq kak :), sisa dana yang ada di deposit akun kakak akan kami kembalikan melalui Trasnfer ke rekening bank kakak :) , jadi menjadi reseller di Tuneeca aman kak deposit awal bisa langsung digunakan.'
    },
    {
        title: 'lupa password',
        group: 'layanan konsumen',
        template: 'Terimakasih telah menghubungi kami, Jika tidak bisa log in karena lupa dengan ID atau password akun kakak, Jangan Kuatir ya kak, karena kakak bisa atur kembali password dan ID dengan mengikuti langkah-langkah mudah pada link berikut ini ---> https://bit.ly/LangkahMudahAturAkun\n\nMohon informasikan kembali jika kakak mengalami kesulitan atau nterjadi error saat melakukan reset password / ID. '
    },
    {
        title: 'FU jika Partner sudah bergabung (ONTEMPORER)',
        group: 'partnership',
        template: '"Terimakasih telah berbelanja produk kami, dan dikarenakan pembelanjaan sudah mencapai lebih dari Rp. 10.000.000,- setelah dipotong diskon Partnership, maka kakak secara otomatis sudah tergabung dalam keanggotaan Partnership Tuneeca Group. Kakak akan mendapatkan diskon khusus keagenan di kategori Agen Contemporer dengan skema diskon sebagai berikut :\n1. Pembelian koleksi Tuneeca New dan Poeva (Non Diskon) akan mendapatkan diskon 27%,\n2. Pembelian koleksi Almari Tuneeca (Non Diskon) akan mendapatkan diskon 25%\n3. Pembelian koleksi Rhupa (non diskon) akan mendapatkan diskon 20%\n4. Pembelian koleksi Tuneeca Signature akan mendapatkan diskon 10%\n5. Dan untuk pembelian produk diskon akan mendapatkan tambahan diskon sampai dengan 7% (misal produk diskon 50% kakak akan mendapatkan  diskon 57%)\n*Diskon akan terpotong otomatis setiap melakukan pembelanjaan di Web\n\nAkun kakak sudah aktif di web kami, berikut akunnya :\nIAkun Partnership kakak sudah aktif di web kami dan sudah bisa digunakan untuk berbelanja, berikut\nakunnya :\nID : ____________\nPassword : ___________\n*Silahkan melakukan penggantian Password untuk keamanan akun. Akun sudah aktif dan sudah bisa digunakan untuk berbelanja \n\nSetiap Partner berhak mendapatkan informasi seputar promo dan launching produk baru yang akan dikirimkan ke email dan WA kakak. Kakak dapat melakukan promosi produk melalui materi promosi yang kami kirimkan melalui WA. Untuk konfirmasi pesanan, keluhan, dan pertanyaan seputar Tuneeca group serta Partnership dapat kakak akses melalui WA ini ya kak. Kakak akan dilayani oleh CS Tuneeca yaitu mbak Gita Islami. Salam kenal ya kak, semoga kedepannya kita bisa bersinergi dengan baik. Selamat bergabung..."'
    },
    {
        title: 'FU partner dari data belanja (Common)',
        group: 'partnership',
        template: 'umum\nAssalamu’alaikum Wr.Wb\nSelamat siang Kak __________________, sebelumnya perkenalkan saya __________ dari Tuneeca, kami ingin mengkonfirmasi bahwa kakak telah melakukan pembelanjaan di Tuneeca sebesar _______________ pada periode __________ s/d ____________________, kami ingin mengucapkan terima kasih banyak atas kepercayaan Kakak terhadap Tuneeca. Sebagai bentuk penghargaan kami kepada Loyal customer kami yang telah berbelanja di Tuneeca, kami ingin mengajak kakak untuk bergabung dalam Start Up Partner Tuneeca, dimana untuk pembelanjaan selanjutnya kakak akan mendapatkan potongan diskon untuk produk baru sampai dengan 20%, dan untuk produk diskon ada tambahan diskon lagi sampai dengan 7%. Bila Kakak berkenan, kami ingin menghubungi kakak untuk menjelaskan lebih lanjut prihal program ini, sekiranya kapan ya Kakak bisa kami hubungi? Kami tunggu responnya ya kak, terima kasih.'
    },
    {
        title: 'Alamat retur ',
        group: 'retur',
        template: 'untuk pesanan yang ingi diretur bisa dikirim ke alamat berikut ya kak\n\nPT. Bina Fajar Estetika (Tuneeca Group Factory)\nUp. Admin Gudang Barang Jadi (GBJ)\nJl. Rancaekek Majalaya RT 001 RW 006, Desa Solokan Jeruk Kecamatan Solokan Jeruk Kabupaten Bandung\n(Patokan depan GOR PGRI Solokan Jeruk)\nPhone : (022) 842265\n\njika barangnya sudah kami terima nanti akan kami infokan kembali ya kak, terima kasih :)'
    },
    {
        title: 'FU partner dari data medsos',
        group: 'partnership',
        template: 'Assalamu\'laikum wr.wb,\n\nSelamat siang kak perkenalkan saya Ulfiah dari tuneeca, sebelumnya mau konfirmasi dari data yang kami dapat bahwa disini kakak nya tertarik untuk bergabung menjadi agen/reseller tuneeca. Bisa diinfokan sebelumnya apa kakak sudah mengenal Tuneeca atau pernah membeli produk Tuneeca Group?\n\nBila Kakak berkenan, kami ingin menghubungi kakak melalui telepon untuk menjelaskan lebih lanjut perihal program ini, sekiranya kapan ya Kakak bisa kami hubungi? Kami tunggu responnya ya kak, terima kasih.'
    },
    {
        title: 'Syarat Partner',
        group: 'partnership',
        template: 'Baik kak, untuk syaratnya sangat mudah, untuk join reseller kakak cukup melakukan pembelanjaan diawal sebesar 3.5jt untuk mendapatkan diskon 20% untuk produk harga normal, kakak bisa langsung mendaftar menjadi Reseller jika sudah melakukan pembelanjaan dengan nominal tersebut, bagaimana kak? sampai sini apa ada yang kurang jelas dan ingin ditanyakan?\nBila Kakak berkenan, kami ingin menghubungi kakak untuk menjelaskan lebih lanjut perihal program ini, sekiranya kapan ya Kakak bisa kami hubungi? Kami tunggu responnya ya kak, terima kasih.'
    },
    {
        title: 'FU partner dari data belanja',
        group: 'partnership',
        template: 'Assalamu’alaikum Wr.Wb\nSelamat siang Kak Nirmala Sari, sebelumnya perkenalkan saya Ulfiah dari Tuneeca, kami ingin mengkonfirmasi bahwa kakak telah melakukan pembelanjaan di Tuneeca sebesar Rp.1.196.000,00 pada periode 01 September 2021 - 18 Oktober 2021, kami ingin mengucapkan terima kasih banyak atas kepercayaan Kakak terhadap Tuneeca. Sebagai bentuk penghargaan kami kepada customer kami yang telah berbelanja di Tuneeca, kami ingin mengajak kakak untuk bergabung dalam Partnership Tuneeca, dimana untuk pembelanjaan selanjutnya kakak akan mendapatkan potongan diskon untuk produk baru sampai dengan 27%, dan untuk produk diskon ada tambahan diskon lagi sampai dengan 10%. Bila Kakak berkenan, kami ingin menghubungi kakak untuk menjelaskan lebih lanjut prihal program ini, sekiranya kapan ya Kakak bisa kami hubungi? Kami tunggu responnya ya kak, terima kasih.'
    },
    {
        title: 'Partner belum ada modal',
        group: 'partnership',
        template: 'jika kakak tidak bisa melakukan deposit di awal, maka kakak bisa mencari customernya dahulu dengan menawarkan produk yang tersedia di web, setelah permintaan pesanan customer mencapai 2jt maka pesanan tersebut bisa langsung di infokan kembali ke Tuneeca dan pesanannya langsung kami proses serta akun Reseller Tuneeca milik kakak langsung kami aktifkan. potongan diskon reseller sebesar 20% dapat kakak gunakan di transaksi berikutnya.'
    },
    {
        title: 'benefit gabung partner',
        group: 'partnership',
        template: 'adapun untuk keuntungan nya sebagai berikut : 1. Mendapatkan potongan diskon untuk produk-produk normal price tanpa ada minimal qty pembelian 2. Memiliki kuota retur setiap bulannya 3. Mendapatkan tambahan potongan diskon untuk produk-produk dengan kategori promo. misalnya produk tersebut sudah diskon 50% maka kakak akan mendapatkan tambahan diskon kembali sebesar 7-10% 4. Sebagai member resmi Tuneeca, kakak memiliki kesempatan besar untuk meningkatkan karir partnershipnya menjadi partner besar dengan mendapatkan potongan diskon 37%. 5. Free biaya Charge Packing produk 6. Free design untuk banner Tuneeca ataupun brosur/flyer untuk promosi di tempat kakak. 7. Soft katalog produk terbaru sebelum launching di website dan masih banyak lagi benefit-benefit yang akan kakak dapatkan'
    },
    {
        title: 'FU Nota',
        group: 'penjualan',
        template: 'Assalamualaikum \n\nDear Tuneeca Lovers :) \n\nBagaimana kabarnya? sebagai informasi saat ini lovers masih memiliki nota pesanan produk yang masih aktif, untuk konfirmasi pembayaran ibu dapat membalas pesan ini :). \n\njika Lovers terkendala untuk transaksi menggunakan virtual account, maka Lovers dapat melakukan transaksi melalui no rekening : 777-157-000-7 An PT. Bina Fajar Estetika, BANK BCA.\n\n\nno nota:\ntotal:'
    },
    {
        title: 'Retur',
        group: 'retur',
        template: 'untuk penukaran produk atau retur produk ketentuannya yaitu : 1. kondisi produk tidak cacat, luntur, dan kelengkapan seperti kancing, plastik pembungkus, hang taq masih tersedia. 2. baju belum di cuci dan di kenakan parfum atau aroma tertentu 3. informasi request retur tidak melebihi 1 minggu dari pembelian dan pengiriman retur produk ke Tuneeca tidak melebihi 30 hari. 4. produk retur berlaku bagi customer yang melakukan pembelanjaan langsung ke Tuneeca atau melalui website Tuneeca, jika pembelian melalui agen/partner Tuneeca, maka dapat dihubungi terlebih dahulu partner Tuneeca yang menjual produk tersebut. 5. Biaya retur dan biaya pengiriman kembali ke customer merupakan tanggungan customer 6. semua produk yang di retur akan terlebih dahulu di lakukan pengecekan , jika tidak memenuhi syarat dan ketentuan maka Tuneeca akan mengirimkan kembali produk retur tersebut menggunakan JNT DFOD ke alamat pengirim'
    },
    {
        title: 'Tuneeca',
        group: 'layanan konsumen',
        template: 'brand kami adalah Tuneeca, Tuneeca Daily (Almari Tuneeca, Simply Look, Rhupa), Poeva dan merchandise, yang mengusung konsep Modern Contemporer dan penyerapan produk kami saat ini sudah mencakup nasional maupun internasional dengan basis komunitas yang besar di indonesia,'
    },
    {
        title: 'Alamat Retur / Soljer',
        group: 'retur',
        template: 'PT. Bina Fajar Estetika (Tuneeca Group Factory)\nUp. Admin Gudang Barang Jadi (GBJ)\nJl. Rancaekek Majalaya RT 001 RW 006, Desa Solokan Jeruk Kecamatan Solokan Jeruk Kabupaten Bandung\n(Patokan depan GOR PGRI Solokan Jeruk)\nPhone : (022) 84226533'
    },
    {
        title: 'Pemenang TLC POin',
        group: 'layanan konsumen',
        template: 'Assalamu\'alaikum wr wb. Selamat Kak Jelvi Yanti terpilih sebagai pemenang aktivasi #TuneecaOTD, Kakak berhak mendapatkan hadiah 100 poin Tuneeca. Agar Kakak dapat melihat merchandise apa saja yang tersedia, boleh kami minta nama akun Kakak yang terdaftar di www.tuneeca.com ?'
    },
    {
        title: 'Pemenang Merch',
        group: 'layanan konsumen',
        template: 'Assalamu\'alaikum wr wb. Selamat Kak Frau Nur Aini terpilih sebagai pemenang aktivasi #TuneecaHappyQuiz TLC. Kakak berhak mendapatkan hadiah N-2020045 bisa diinfokan untuk alamat lengkap kakak, untuk kami kirimkan hadiahnya :) terima kasih'
    },
    {
        title: 'Ketersediaan Produk',
        group: 'Stok produk',
        template: 'untuk koleksi PV-0321005 masih tersedia Size XS, S, M, L, XL dengan harga Rp. 474.000\n\nbisa diinfokan kakak memiliki LD berapa? agar kami bantu sesuaikan sizenya :)'
    },
    {
        title: 'Poduk Tidak Tersedia',
        group: 'Stok produk',
        template: 'mohon maaf kak untuk koleksi tersebut sudah tidak tersedia, apa ada pilihan lainnya? atau bisa diinfokan kakak sedang mencari busana seperti apa? agar kami bantu carikan referensi produk kami sesuai busana yang kakak cari :)'
    },
    {
        title: 'Cara Cari Produk',
        group: 'produk',
        template: 'hallo untuk memudahkan kakak melihat koleksi yang kakak cari, silahkan ikuti cara berikut ya \n1. buka aplikasi Tuneeca / kunjungi web www.tuneeca.com\n2. klik garis tiga pada bagian kanan atas (pencarian melalui handphone)\n3. pilih menu produk - kategori (pilih kategori baju yg kakak inginkan)\n4. pilih urut dan saring\n5. pilih size yang ingin kakak cari, atur min dan max harga sesuai yang kakak ingin\n6. klik terapkan pada halaman ini, nanti akan muncul koleksi dengan pilihan yg kakak cari :)'
    },
    {
        title: 'Format Pesanan',
        group: 'partnership',
        template: 'untuk pemesanan bisa melengkapi data berikut : \nNama : \nAlamat Email : \nPhone Number : \nAlamat Pengiriman : \nKode produk : \nUkuran :\n\nApabila nota tercancel:\nhallo kak, selamat siang :)\nsebelumnya mohon maaf untuk nota pesanan kakak 100412515 sudah tercancel karena belum ada pembayaran setelah 3x24 jam. jika kakak ingin memesan kembali bisa isi format berikut ya \nnama:\nalamat:\nno hp:\nemail:\nkode, size, qyt:'
    },
    {
        title: 'Postingan Tidak Terdeteksi',
        group: 'layanan konsumen',
        template: 'mohon maaf kak kami tidak bisa mendetek postingan yang kakak share/pesan yang kakak reply :) untuk itu bisa diinfokan untuk produk kode berapa? atau kakak bisa kirim fotonya ya kak :)'
    },
    {
        title: 'Konfirmasi penjualan',
        group: 'penjualan',
        template: 'Baik kak, untuk nota pemesanan nya 100408837 Total : Rp639.000,00 bisa transfer ke no rek BCA 777-157-0007 a/n PT Bina Fajar Estetika, atau melalui multipayment MANDIRI dengan kode perusahaan 40020 . Mohon lampirkan bukti trf jika sudah melakukan pembayaran dan mohon cantumkan nomor nota pada keterangan pembayaran.'
    },
    {
        title: 'Konfirmasi setelah bayar',
        group: 'penjualan',
        template: 'Baik kak, untuk transfernya akan kami cek terlebih dahulu, dan pesanan akan kami kirimkan ke alamat kakak.\n\n\nDan untuk informasi tambahan, Lovers bisa mengecek Status Pengiriman dan Nomor Resi dengan cara sebagai berikut:\n1. login pada website www.tuneeca.com/install aplikasi Tuneeca melalui Playstore\n2. klik Profil/Akun Saya\n3. klik Riwayat Transaksi\n4. Klik Lihat Pemesanan\n5. Status Pengiriman dan Nomor Resi akan muncul di paling bawah \n\n\nJika sudah terima produknya jangan lupa untuk foto dan upload fotonya di Instagram dengan Hashtag #TUNEECAOTD. Akan ada hadiah menarik jika foto kakak terpilih menjadi The Best Outfit Of The Day :)'
    },
    {
        title: 'COD',
        group: 'penjualan',
        template: 'Untuk sistem COD di Tuneeca, kakak perlu membayar diawal untuk FEE COD + ONGKIR (via transfer bank) setelah pembayaran tersebut terkonfirmasi maka kami akan proses pesanan kakak (pesanan dikirim ke alamat yang dituju), dan selanjutnya kakak bisa melakukan pembayaran di tempat untuk produk/pesanan yang kakak beli :)\n\nhttp://bit.ly/CODTuneeca'
    },
    {
        title: 'KonFImasi Bayar COD',
        group: 'penjualan',
        template: 'pembayaran'
    },
    {
        title: 'Closing',
        group: 'penjualan',
        template: 'Baik Kak, untuk melihat koleksi menarik lainnya kakak bisa kunjungi www.tuneeca.com dan jangan sampai lewatkan info promo dan produk terbaru dari kami dengan pantau dan ikuti media sosial kami di https://linktr.ee/tuneeca 🙂'
    },
    {
        title: 'Tukar Poin',
        group: 'layanan konsumen',
        template: '1. klik titik tiga sebelah kanan atas (dekat dengan keranjang)\n2. pilih menu poin (scroll ke bawah)\n3. pilih mercahandise yang akan dipilih untuk ditukarkan'
    },
    {
        title: 'Shopee',
        group: 'penjualan',
        template: 'untuk mengunjungi shopee kami kakak bisa klik link berikut ya \nhttps://shopee.co.id/tuneecaofficial'
    },
    {
        title: 'Werb Bermasalah',
        group: 'layanan konsumen',
        template: 'mohon maaf saat ini web kami sedang bermasalah dan masih dalam perbaikan, sehingga nota-nota belum bisa kami proses, jika web sudah normal kembali kami akan langsung proses nota satu per satu ya kak. mohon maaf atas ketidak nyamanannya, terima kasih :)'
    },
    {
        title: 'peringatan TLC',
        group: 'layanan konsumen',
        template: 'Salam bunda, Terimakasih telah bergabung bersama komunitas pecinta Tuneeca, Tuneeca Lovers Community. Bunda dapat berbagi segala hal mengenai fashion muslim wanita dan hal-hal lainnya mengenai wanita, kecuali untuk berjualan. Untuk berjualan produk Tuneeca atau produk lainnya tidak diperkenankan di post di TLC, karena itu postingan bunda akan kami hapus. Untuk selanjutnya bunda dapat berinteraksi dengan member lain atau post hal-hal positif yang tidak mengarah ke penjualan produk apapun. Terimakasih.'
    },
    {
        title: 'Fu Target Partner (1)',
        group: 'partnership',
        template: 'Assalamu\'alaikum Wr, Wb\nDear Partner, \nsemoga Partner dan keluarga diberikan kesehatan dan selalu berada dalam lindungan-Nya. Melalui pesan ini kami ingin memberitahukan total pembelanjaan Partner hingga hari ini \ntanggal 26 Maret 2022 adalah sebesar Rp.\nDan kekurangan pembelanjaan bulan ini adalah Rp. \nKami ucapkan terimakasih untuk support yang telah Partner berikan, meski saat ini kita semua tengah berada di situasi yang kurang menyenangkan. \nTetap semangat dan selalu jaga kesehatan Partner. Masih ada beberapa hari lagi untuk mengejar kekurangan pembelanjaan Partner hingga tanggal 31 Maret 2022.\nDemikian informasi ini kami sampaikan, tetap semangat dan selalu jaga protokol kesehatan untuk lindungi diri dan keluarga. \nTerimakasih Atas Perhatiannya\nWassalam,'
    },
    {
        title: 'FU Target Partner  (2)',
        group: 'partnership',
        template: 'Assalamu\'alaikum Wr, Wb\n \nDear Partner, \nsemoga Partner dan keluarga diberikan kesehatan dan selalu berada dalam lindungan-Nya. Melalui pesan ini kami ingin memberitahukan total pembelanjaan Partner hingga hari ini \ntanggal 26 Maret 2022, adalah sebesar Rp.0,00\n \nMeski saat ini kita semua tengah berada di situasi yang kurang menyenangkan, tetap semangat dan selalu jaga kesehatan Partner. Masih ada Beberapa hari kedepan untuk mengejar kekurangan pembelanjaan Partner hingga tanggal 31 Maret 2022.\n \nDemikian informasi ini kami sampaikan, tetap semangat dan selalu jaga protokol kesehatan untuk lindungi diri dan keluarga. \n \nTerimakasih Atas Perhatiannya\nWassalam,'
    },
    {
        title: 'Recruitment Partner',
        group: 'partnership',
        template: 'Halo kak:) baik kak, sebelum kami jelaskan mengenai caranya, bisa diinfokan dengan kakak siapa dan domisili mana?'
    },
    {
        title: 'Setelah perkenalan nama',
        group: 'partnership',
        template: 'Tanyakan sudah tau produk tuneeca atau belum\nbaik Kak Lela salam kenal ya kak :) oh iya kak, sebelumnya apa kakak sudah mengenal Tuneeca atau pernah membeli produk Tuneeca Group?'
    },
    {
        title: 'Setelah menanyakan produk tuneeca',
        group: 'partnership',
        template: 'Tanyakan dahulu mengenai bisnis\nbaik kak, berarti sudah kenal produk Tuneeca ya kak :) Oh iya kalau saat ini Kak Lela sudah memiliki bisnis online/offline atau baru akan mulai berbisnis kak?'
    },
    {
        title: 'Offering Partner',
        group: 'partnership',
        template: 'jelaskan mengenai partner\nsampai sini apa ada yang kurang jelas dan ingin ditanyakan dulu kak?'
    },
    {
        title: 'Pertanyaan Promo',
        group: 'promo',
        template: 'Baik kak, untuk jadwal promo ditunggu ya kak, \nnanti apabila ada promo akan kami infokan di ig/fb, \njangan lupa cek terus instagram dan facebook \nTuneeca agar tidak ketinggalan infonya ya kak :)'
    },
    {
        title: 'Cara Bayar Multipayment',
        group: 'pembayaran',
        template: 'Login dengan masukan ID & password Livin by Mandiri\n1.Pilih menu bayar, lalu buat pembayaran baru.\n2.Pilih Multipayment\n3.Pilih penyedia jasa, \n4.ketik “Tuneeca” dan pilih “Tuneeca” sebagai penyedia jasa, pastikan penyedia jasa dengan nomor “40020”\n5.Isi nama pembayaran dengan “Nama Anda” dan nomor referensi dengan “nomor invoice/nomor nota”\n6.pastikan nama dan nomor invoice sudah benar, masukan nominal transferan sesuai dengan nominal pembayaran yang tertera pada invoice\n7.pastikan nominal transfer sudah sesuai dengan invoice, lalu klik konfirmasi\n8.masukan PIN mandiri Livin anda\n9.kirimkan bukti transferan ke nomor WhatsApp Tuneeca 08112225702 atau email ke cs@tuneeca.com '
    },
    {
        title: 'Tata Cara Order',
        group: 'penjualan',
        template: 'Tata cara melakukan pembelanjaan melalui website\n1. Masuk / Log in menggunakan akun yang telah terdaftar di web www.Tuneeca.com\n2. Untuk memilih produk anda bisa melakukan pencarian dengan memasukan SKU produk di kolom pencarian, \n3. atau klik menu produk lalu pilih berdasarkan release / daily needs / produk / kategori, lalu pilih salah satu nama katalog/kategori produk tersebut\n4. setelah itu muncul tampilan beberapa produk, lalu pilih salah satu produk yang ingin dibeli dengan klik produk tersebut\n5. klik varian warna / ukuran yang akan dibeli, lalu masukan jumlah produk (jika tidak ada varian maka langsung masukan jumlah produk)\n6. klik tombol masukan ke keranjang\n7. klik logo keranjang, lalu klik tombol checkout\n8.masukan alamat pengiriman (terdiri dari nama penerima, alamat email, no hp, provinsi,kota, kecamatan, alamat detail dan kode pos)\n9. klik tombol simpan dan lanjutkan\n10. pilih metode pembayaran (menggunakan virtual account atau melalui minimarket)\n11. lalu klik tombol pesan\n12. maka order selesai dibyuat, dan rincian pesanan yang telah dibuat akan muncul'
    },
    {
        title: 'Link Login Website',
        group: 'kendala web',
        template: 'Klik link berikut untuk login\n\n<%= url %>'
    },
    {
        title: 'Link Login Mobile App',
        group: 'kendala web',
        template: 'Klik link berikut untuk login\n\n<%= url %>'
    },
    {
        title: 'WA Channel',
        group: 'partnership',
        template: 'Dapatkan update eksklusif langsung di WhatsApp kamu! Follow channel Tuneeca untuk informasi terbaru tentang launching, diskon promo, dan informasi terupdate lainnya.\n\n📲 Klik link ini untuk join: https://whatsapp.com/channel/0029VahXrOL9Bb60fjayLk3d'
    },
    {
        title: 'J&T Retail',
        group: 'layanan konsumen',
        template: '[PENGUMUMAN PENTING]\n\n📢 Pengiriman menggunakan request pickup J&T (order mandiri via J&T) akan dinonaktifkan sementara hingga 28 Maret 2025 dan akan kembali aktif setelah libur Lebaran.\n\n✅ Pengiriman melalui J&T tetap bisa dilakukan dengan sistem drop-off. Paket akan kami serahkan ke agen J&T dengan batas waktu maksimal H+3 dari tanggal pembayaran pesanan kami terima.\n\n📦 Karena kami tidak memiliki akses dan kerja sama dengan J&T, maka apabila terjadi kendala proses pengiriman (setelah barang kami drop-off di agen J&T) seperti: keterlambatan, kehilangan atau kerusakan barang selama pengiriman, kendala tersebut dapat disampaikan langsung ke J&T agar bisa mendapatkan solusi yang lebih tepat. \n\n🔄 Jika terjadi kendala pada proses pengiriman (misalnya alamat tidak ditemukan) dan paket dikembalikan ke gudang drop-off J&T, customer dapat mengajukan retur ke kami. Namun, retur hanya bisa diproses setelah barang sampai di gudang kami. Pengiriman dari gudang drop-off J&T ke gudang kami menjadi tanggung jawab customer.\n\nTerima kasih atas kerja samanya! Jika ada pertanyaan, silakan hubungi tim kami. 🙏'
    },
    {
        title: 'J&T Partner',
        group: 'layanan konsumen',
        template: '[PENGUMUMAN PENTING]\n\n📢 Pengiriman menggunakan request pickup J&T (order mandiri via J&T) akan dinonaktifkan sementara hingga 28 Maret 2025 dan akan kembali aktif setelah libur Lebaran.\n\n✅ Pengiriman melalui J&T tetap bisa dilakukan dengan sistem drop-off. Paket akan kami serahkan ke agen J&T dengan batas waktu maksimal H+3 dari tanggal pembayaran pesanan kami terima.\n\n⏳ Jika terjadi keterlambatan, kami sarankan Partner untuk langsung menghubungi J&T, karena kami tidak memiliki akses untuk mengajukan komplain terkait keterlambatan pengiriman.\n\n📦 Karena kami tidak memiliki akses dan kerja sama dengan J&T, maka apabila terjadi kendala proses pengiriman (setelah barang kami drop-off di agen J&T) seperti: kehilangan atau kerusakan barang selama pengiriman, kendala tersebut dapat disampaikan langsung ke J&T agar bisa mendapatkan solusi yang lebih tepat. Sehingga:\n1️⃣ Partner wajib menyelesaikan proses penggantian barang hilang maksimal H+10 kalender dari tanggal seharusnya paket diterima customer.\n2️⃣ Partner wajib menyelesaikan proses penggantian/kompensasi barang rusak maksimal H+10 kalender sejak barang diterima customer.\n\n⚠️ Jika ketentuan di atas tidak dipenuhi, layanan pembelanjaan Partner ke Tuneeca akan diberhentikan sementara hingga masalah dengan customer terselesaikan.\n\n🔄 Jika ada kendala pengiriman (misalnya alamat tidak ditemukan) dan paket dikembalikan ke gudang drop-off J&T, Partner dapat mengajukan retur ke kami. Namun, retur hanya bisa diproses setelah barang sampai di gudang kami. Pengiriman dari gudang drop-off J&T ke gudang kami menjadi tanggung jawab Partner.\n\nTerima kasih atas kerja samanya! Jika ada pertanyaan, silakan hubungi tim kami. 🙏'
    },
]